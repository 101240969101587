<template  src="@/components/GlobalView/footer-page/FooterPage-template.vue" ></template>
<style src="@/components/GlobalView/footer-page/FooterPage.scss" lang="scss" scoped></style>
<script>
export default {
    name: 'FooterPage',
    data: function () {
        return {
            enlacesFooter: [{
                url: 'https://www.supertransporte.gov.co/',
                img: 'logo-si.png',
                size: 110,
                redireccion: '_blank'
            }, {
                url: 'http://www.magdalena.gov.co/',
                img: 'logo-gm.png',
                size: 120,
                redireccion: '_blank'
            },
            {
                url: 'https://www.santamarta.gov.co/',
                img: 'santa-marta-logo.png',
                size: 130,
                redireccion: '_blank'
            }]
        }
    }
}
</script>
