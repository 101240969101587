<template>
    <router-view />
</template>
<style lang="scss">
@import 'assets/ui/reset.css';
@import 'assets/ui/bootstrap.css';
@import 'assets/ui/apock-style.min.css';
@import 'assets/ui/icofont/icofont.css';
@import 'assets/ui/font-awesome/font-awesome.css';
@import 'assets/ui/animate.css';
@import 'assets/ui/silder-css-infinite.css';

</style>
