<template src="./SectionWelcome-template.vue"></template>
<style lang="scss" scoped src="./SectionWelcome.scss"></style>

<script>
export default {
    name: 'SectionWelcome',
    data: function () {
        return {

        }
    }
}
</script>
