<template>
    <footer>
        <div class="dowload offset-md-1">
            <div class="implement-bloq">
                <div class="bloq-avatar size-xx border:xiv round:xiii">
                    <img src="@/assets/files/img/index/colorear-bus.png" alt="">
                </div>
                <div class="bloq ms-4">
                    <h3 class="text-xviii text-uppercase">Descargar dibujo</h3>
                    <button type="button" class="btn orange:theme w-100">
                        Descargar
                    </button>
                </div>
            </div>
            <img class="colores d-none d-md-block" src="@/assets/files/img/index/lapice-colores-text.png" alt="">
        </div>
        <div class="d-block pt-4">
<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner text-center">
    <div class="carousel-item active">
      <div class="row align-items-center mx-auto col-9">
          <div class="col-6 col-md">
              <a target="_black" href="https://id.presidencia.gov.co/deinteres/index.html">
                  <img src="@/assets/files/img/carrucel/f-1.webp" alt="" width="100">
              </a>
          </div>
          <div class="col-6 col-md">
              <a target="_black" href="http://www.magdalena.gov.co/">
                  <img src="@/assets/files/img/carrucel/f-white-2.png" alt="" width="100">
              </a>
          </div>
          <div class="col-6 col-md">
                <a target="_black" href="https://www.supertransporte.gov.co/">
                    <img src="@/assets/files/img/carrucel/f-3.png" alt="" width="90">
                </a>
          </div>
          <div class="col-6 col-md">
              <a target="_black" href="https://www.policia.gov.co/">
                  <img src="@/assets/files/img/carrucel/f-4.png" alt="" width="110">
              </a>
          </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="row align-items-center mx-auto col-9">
          <div class="col-6 col-md">
              <a target="_black" href="https://www.mintransporte.gov.co/">
                  <img src="@/assets/files/img/carrucel/f-5.png" alt="" width="200">
              </a>
          </div>
          <div class="col-6 col-md">
              <a target="_black" href="https://www.mintic.gov.co/portal/inicio/Glosario/G/5306:Gobierno-en-Linea-GEL">
                  <img src="@/assets/files/img/carrucel/f-6.png" alt="" width="100">
              </a>
          </div>
          <div class="col-6 col-md">
              <a target="_black" href="https://www.santamarta.gov.co/">
                  <img src="@/assets/files/img/carrucel/f-white-7.png" alt="" width="90">
              </a>
          </div>
          <div class="col-6 col-md">
              <a target="_black" href="https://id.presidencia.gov.co/deinteres/index.html">
                  <img src="@/assets/files/img/carrucel/f-1.webp" alt="" width="100">
              </a>
          </div>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
        <div class="d-block tema-principal footer-container text-light">
            <section class="container">
                <div class="row container-footer">
                    <div class="col-lg-4 col-12 mb-4 mb-md-0 footer-atajos">
                        <h5>Oficina Principal</h5>
                        <hr>
                        <ul>
                            <li class="d-flex align-items-center">
                                <div class="col-auto">
                                    <i class="icon-location h3"></i>
                                </div>
                                <div class="col">
                                    Calle 41 No. 31-17 Carretera Troncal del Caribe Variante Gaira-Mamatoco Santa Marta-Colombia
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <div class="col-auto">
                                    <i class="icon-mail2 h3"></i>
                                </div>
                                <div class="col">
                                    <h5>Contacto</h5>
                                    <a href="mailto:centraltsm@terminaldesantamarta.com">centraltsm@terminaldesantamarta.com</a>
                                    <br>
                                    <a href="mailto:centraltsm@hotmail.com">centraltsm@hotmail.com</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-12 mb-4 mb-md-0 footer-atajos horario-list">
                        <h5>Horario de atención:</h5>
                        <hr>
                        <div class="d-flex">
                            <div class="col-auto">
                                <i class="icon-clock h3"></i>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col-12 border-bottom pb-3">
                                        <h6>Atención Administrativa (de oficina)</h6>
                                        <small>De lunes a viernes: 8 am - 12m | 2pm -6pm</small>
                                    </div>
                                    <div class="col-12 border-bottom py-3">
                                        <h6>De Atención al usuario</h6>
                                        <small>8am - 6pm</small>
                                    </div>
                                    <div class="col-12 pt-3">
                                        <h6>Atención de venta de tiquetes:</h6>
                                        <small>4 am -11 pm</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-12 mb-4 mb-md-0 footer-atajos">
                        <h5>Entidad</h5>
                        <hr>
                        <ul>
                            <li>
                                <a href="https://terminaldesantamarta.com/entidad/tranparenciaAcceso">Transparencia y acceso</a>
                            </li>
                            <li>
                                <a href="https://terminaldesantamarta.com/entidad/informacion-corporativa">Información corporativa</a>
                            </li>
                            <li>
                                <a href="#">Contrataciones</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 mt-5">
                    <div class="row align-items-center justify-content-center text-center">
                        <div class="col-md-auto mb-5 mr-4 mb-md-0 col-12">
                            <div class="row align-items-center">
                                <a :target="item.redireccion" :href="item.url" class="col" v-for="(item, index) of enlacesFooter" v-bind:key="index">
                                    <img :width="item.size" :src="require(`@/assets/files/img/index/${item.img}`)" alt="terminal de transporte santa marta">
                                </a>
                            </div>
                        </div>
                        <div class="col-md-auto mb-5 mb-md-0 col-12">
                            <a href="https://terminaldesantamarta.com/contactar" class="btn bg-white btn-lg color-principal">
                                <i class="icon-phone mr-3"></i> Contactanos
                            </a>
                        </div>
                        <div class="col-md-auto mb-5 ml-4 mb-md-0 col-12">
                            <ul class="list-social d-inline-flex">
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/Terminal-Santa-Marta-831588453872160/" title="">
                                        <i class="icon-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com/TerminalSanMart" title="">
                                        <i class="icon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="#" title="">
                                        <i class="icon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </footer>
</template>
