<template>
    <div class="section-bus bg-img-playa" :style="{ 'background-image': `url(${require('@/assets/files/img/index/playa.png')})` }">
        <div class="img-calle">
            <img :src="require('@/assets/files/img/index/calle.png')" alt="">
        </div>
        <div class="img-kids">
            <img class="backInDown animated repeat-1 d-block" :src="require('@/assets/files/img/index/text-terminal-kids.png')" alt="">
        </div>
        <div class="slider d-none d-md-block" style="position: absolute; bottom: 0; left: 0;">
            <div class="slide-track">
                <div class="slide" v-for="i in 14" :key="i">
                    <img  :src="require('@/assets/files/img/index/barra-carretera.png')" height="100" width="250" alt="">
                </div>
            </div>
        </div>
        <div class="img-bus animate-bus">
            <img class="bounceInLeft animated repeat-1 d-block" :src="require('@/assets/files/img/index/bus.png')" alt="">
        </div>
    </div>
</template>
