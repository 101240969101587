<template>
    <header class="row justify-content-between align-items-center ocre:theme pt-2">
        <div class="col-auto marg:row-xii">
           <ButtonIcon icon="fa fa-facebook" link="https://www.facebook.com/Terminal-Santa-Marta-831588453872160/"></ButtonIcon>
           <ButtonIcon icon="fa fa-twitter" link="https://twitter.com/TerminalSanMart"></ButtonIcon>
           <ButtonIcon icon="fa fa-instagram" link="https://www.instagram.com/terminalsantamarta"></ButtonIcon>
        </div>
        <div class="col-12 order-1 order-md-0 text-center my-3 my-md-0 col-md-auto marg:row-xiii">
            <a href="https://www.magdalena.gov.co/">
                <img :src="require('@/assets/files/img/logos/fuerza-ciudadana.png')" width="100" alt="">
            </a>
            <a href="https://terminaldesantamarta.com/">
                <img :src="require('@/assets/files/img/logos/logo-ts-white.png')" width="100" alt="">
            </a>
            <a href="https://www.santamarta.gov.co/">
                <img @click="dowload" :src="require('@/assets/files/img/logos/sm-cambio-white.png')" width="80" alt="">
            </a>
        </div>
        <div class="col-auto marg:row-xii">
            <span>Contactar</span>
           <ButtonIcon icon="fa fa-phone" link="https://terminaldesantamarta.com/contactar"></ButtonIcon>
        </div>
        <div class="col-12 mt-3 order-last">
            <div class="row justify-content-center">
                <nav class="col-auto">
                    <ul class="row">
                        <li class="col-12 mb-1 col-md">
                            <a href="https://terminaldesantamarta.com/" class="d-block  parr-unalinea px-5 py-2 orange:bg.tx white:br hover-yellow:theme round:xv border-dashed">Inicio</a>
                        </li>
                        <li class="col-12 mb-1 my-md-0 col-md">
                            <a href="#quienesSomos" class="d-block  parr-unalinea px-5 py-2 orange:bg.tx white:br hover-yellow:theme round:xv border-dashed">Quienes somos?</a>
                        </li>
                        <li class="col-12 mb-1 col-md">
                            <a href="#queHacemos" class="d-block parr-unalinea px-5 py-2 orange:bg.tx white:br hover-yellow:theme round:xv border-dashed">Que hacemos</a>
                        </li>
                        <li class="col-12 col-md">
                            <a href="#tipBuenViajero" class="d-block parr-unalinea px-5 py-2 orange:bg.tx white:br hover-yellow:theme round:xv border-dashed">Tips del buen viajero</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>
