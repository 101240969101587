<template>
    <a target="_black" :href="link" class="btn white:br.tx yellow-shadow shadow-solid-xi round:xiii bloq-icono size-xv border:xi">
         <i :class="icon"></i>
    </a>
</template>
<script>
export default {
    name: 'ButtonIcon',
    props: {
        icon: String,
        link: String
    },
    data: function () {
        return {

        }
    }
}

</script>
