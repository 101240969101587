<template src="./SectionUs-template.vue"></template>
<style lang="scss" scoped src="./SectionUs.scss"></style>
<script>
export default {
    name: 'SectionUs',
    data: function () {
        return {

        }
    }
}
</script>
