<template>
    <div class="container-fluid">
        <NavTop></NavTop>
    </div>
    <SectionWelcome></SectionWelcome>
    <SectionUs></SectionUs>
    <FooterPage></FooterPage>
</template>
<script>
import NavTop from '@/components/GlobalView/nav-top/NavTop.vue'
import FooterPage from '@/components/GlobalView/footer-page/FooterPage.vue'
import SectionWelcome from '@/components/HomeView/SectionWelcome/SectionWelcome.vue'
import SectionUs from '@/components/HomeView/SectionUs/SectionUs.vue'
export default {
    name: 'HomeView',
    components: {
        NavTop,
        SectionWelcome,
        FooterPage,
        SectionUs
    }
}
</script>
