<template>
    <div class="cielo" id="quienesSomos">
        <div class="d-block section-intro">
            <img :src="require('@/assets/files/img/index/nuves.png')" alt="" class="w-100 img-nuves">
            <div class="d-flex align-items-end justify-content-center section-title">
                <div class="d-block text-somos">
                    <div class="display-2 tex-efecto3d fw-bold">Quienes somos?</div>
                </div>
            </div>
            <div class="d-flex text-center my-4 justify-content-center section-us">
                <p class="col-7 fs-3 white:tx">Una Terminal que presta servicio de transporte de pasajeros por carretera, brindando a sus usuarios información útil,  comodidad y  seguridad dentro de sus instalaciones. </p>
            </div>
        </div>
        <div class="container-fluid section-info" id="queHacemos">
            <div class="row">
                <div class="col-md-8 col-lg-6 offset-md-1">
                    <div class="d-flex flex-wrap align-items-center secttion-item-info">
                        <span class="d-block mb-3">
                            <h3 class="tex-efecto3d fs-1">Que hacemos en la terminal de transporte? </h3>
                        </span>
                        <div class="card-message shadow-solid-xiv text-white fs-4 font:i red-shadow mb-5">
                            <p>
                                Prestamos el servicio de terminal de transporte, donde los viajeros pueden tomar buses para viajar a diferentes destinos. En la Terminal encontraras servicios como restaurantes, cafetería, locales comerciales,  taxis, cajeros automáticos entre otros que harán más agradable tu visita y seguro tu viaje.
                            </p>
                        </div>
                        <span class="d-block mb-3" id="tipBuenViajero">
                            <h3 class="tex-efecto3d fs-1">Tips para ser un buen viajero!!!! </h3>
                        </span>
                        <div class="card-message shadow-solid-xiv text-white fs-4 font:i red-shadow mb-5">
                            <p>-Cuando Viajes dile a tus papitos que compren sus tiquetes solo en  las taquillas autorizadas dentro de la terminal. </p>
                        </div>
                        <div class="card-message shadow-solid-xiv text-white fs-4 font:i red-shadow mb-5">
                            <p>-Recuérdale a tus papás que por seguridad, es importante abordar los buses de transporte solo en la Terminal. </p>
                        </div>
                        <div class="card-message shadow-solid-xiv text-white fs-4 font:i red-shadow mb-5">
                            <p>-Dile a tus papás que a la hora de viajar deben llevar tu documento de identidad. </p>
                        </div>
                        <div class="card-message shadow-solid-xiv text-white fs-4 font:i red-shadow mb-5">
                            <p>-Si viajas con tu mascota, no olvides llevarla en un guacal y en buen estado de salud. </p>
                        </div>
                        <div class="card-message shadow-solid-xiv text-white fs-4 font:i red-shadow mb-5">
                            <p>- Si te sientes mal o sufres algún tipo de accidente,  dile a tus papás que en la Terminal contamos con el servicio de zona protegida y ambulancia. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-mas-info">
            <img class="img-ciudad" :src="require('@/assets/files/img/index/ciudad.png')" alt="">
            <img class="img-carretera" :src="require('@/assets/files/img/index/carretera.png')" alt="">
            <div class="img-bus-2">
                <img :src="require('@/assets/files/img/index/bus-2.png')" alt="">
            </div>
        </div>
    </div>
</template>
