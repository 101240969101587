<template src="@/components/GlobalView/nav-top/NavTop-template.vue"></template>
<style src="@/components/GlobalView/nav-top/NavTop.scss" lang="scss" scoped></style>
<script>
import ButtonIcon from '@/components/ui-component/ButtonIcon.vue'

export default {
    name: 'NavTop',
    components: {
        ButtonIcon
    },
    data: function () {
        return {
            hola: 'hola'
        }
    },
    methods: {
        dowload () {
            const source = 'https://www.google.es/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png'
            const a = document.createElement('a')
            a.download = true
            a.target = '_blank'
            a.href = source
        }
    }
}
</script>
